import { TPrivileges, Features, Permissions } from '../routes/index';

export const PRIVILEGES_MOCKS: Record<Permissions, TPrivileges> = {
  [Permissions.read]: {
    [Features.FEATURE_A]: [Permissions.read],
  },
  [Permissions.create]: {
    [Features.FEATURE_A]: [Permissions.read, Permissions.create],
  },
  [Permissions.delete]: {
    [Features.FEATURE_A]: [
      Permissions.read,
      Permissions.create,
      Permissions.delete,
    ],
  },
  [Permissions.update]: {
    [Features.FEATURE_A]: [
      Permissions.read,
      Permissions.create,
      Permissions.delete,
      Permissions.update,
    ],
  },
};
