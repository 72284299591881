import apiClient from './ApiClientConfig';
import {
  requestInterceptor,
  requestInterceptorError,
} from './interceptors/RequestInterceptor';
import { responseInterceptor } from './interceptors/ResponseInterceptor';
import { responseInterceptorError } from './interceptors/ResponseInterceptorError';

apiClient.interceptors.request.use(requestInterceptor, requestInterceptorError);

apiClient.interceptors.response.use(
  responseInterceptor,
  responseInterceptorError
);

export default apiClient;
