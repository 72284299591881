import UserContext from '../context/user/UserContext';
import React from 'react';

export const useUser = () => {
  const context = React.useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};
