import { Outlet } from "react-router-dom";

const AuthenticationLayout: React.FC = () => {
  return (
    <div className="container-fluid login-container">
      <div className="row h-100">
        <div className="col-8 login-background"></div>
        <div className="col-4 d-flex align-items-center justify-content-center">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthenticationLayout;
