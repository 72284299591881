import { NewUser } from '../../models/User';

export const userService = {
  async getUsers() {
    try {
      const response = await fetch(
        'http://127.0.0.1:8090/api/collections/users/records',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: 'Admin <ADMIN_TOKEN>',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch users:', error);
      throw error;
    }
  },

  async getUserDetail(userId: any) {
    try {
      const response = await fetch(
        `http://127.0.0.1:8090/api/collections/users/records/${userId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: 'Admin <ADMIN_TOKEN>',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch user detail:', error);
      throw error;
    }
  },

  async postUser(user: NewUser) {
    user.status = false;
    user.password = 'password';
    try {
      const response = await fetch(
        'http://127.0.0.1:8090/api/collections/users/records',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: 'Admin <ADMIN_TOKEN>',
          },
          body: JSON.stringify(user),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to post user:', error);
      throw error;
    }
  },

  async updateUser(userId: string, updatedData: Partial<NewUser>) {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await fetch(
        `http://127.0.0.1:8090/api/collections/users/records/${userId}`,
        {
          method: 'PATCH',
          headers,
          body: JSON.stringify(updatedData),
        }
      );

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(
          `Error ${response.status}: ${response.statusText}. Details: ${errorBody}`
        );
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to update user:', error);
      throw error;
    }
  },

  async requestPasswordReset(email: any) {
    console.log(email);
    try {
      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await fetch(
        `http://127.0.0.1:8090/api/collections/users/request-password-reset`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify(email),
        }
      );

      if (response.status === 204) {
        return {}; // No content, return an empty object
      }

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(
          `Error ${response.status}: ${response.statusText}. Details: ${errorBody}`
        );
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to request password reset:', error);
      throw error;
    }
  },

  async confirmPasswordReset(
    token: string,
    password: string,
    passwordConfirm: string
  ) {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await fetch(
        `http://127.0.0.1:8090/api/collections/users/confirm-password-reset`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({ token, password, passwordConfirm }),
        }
      );

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(
          `Error ${response.status}: ${response.statusText}. Details: ${errorBody}`
        );
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to confirm password reset:', error);
      throw error;
    }
  },
};
