import { FC } from "react";
import { TProtectRouteProps } from "../types/routing";
import { Navigate } from "react-router-dom";

const ProtectedRoute: FC<TProtectRouteProps> = ({
  isAuthenticated,
  authenticationPath,
  outlet,
}) => {
  if (isAuthenticated) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
};

export default ProtectedRoute;
