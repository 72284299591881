import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { TProtectRouteProps } from "../types/routing";
import { appRoutes, IRoute } from "../appRoutes";
import ProtectedRoute from "./ProtectedRoute";

const RoutesWrapper = () => {
  const { user, isInitialized } = useUser();

  if (!isInitialized) {
    return <div>Loading...</div>;
  }
  const protectedRouteProps: Omit<TProtectRouteProps, "outlet"> = {
    isAuthenticated: user.isAuthenticated,
    authenticationPath: "/auth/login",
  };

  const renderRoute = (route: IRoute) => {
    const { id, path, exact, isProtected, component: Page, children } = route;

    // Redirect authenticated users away from non-protected "auth" routes
    if (!isProtected && user.isAuthenticated) {
      return (
        <Route
          key={path}
          path={path}
          element={<Navigate to="/app/dashboard" replace />}
        />
      );
    }

    const mainRoute = (
      <Route
        key={id}
        path={path}
        element={
          Page ? (
            isProtected ? (
              <ProtectedRoute {...protectedRouteProps} outlet={<Page />} />
            ) : (
              <Page />
            )
          ) : null
        }
      >
        {children && children.map(renderRoute)}
      </Route>
    );

    return mainRoute;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            user.isAuthenticated ? (
              <Navigate to="/app/dashboard" replace />
            ) : (
              <Navigate to="/auth/login" replace />
            )
          }
        />
        {appRoutes.map(renderRoute)}
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesWrapper;
