import React, { useState } from "react";
import "./ForgotPassword.scss";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  Label,
  Error,
  Hint,
  FloatingLabel,
} from "@progress/kendo-react-labels";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
  FieldRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useNavigate } from "react-router-dom";
import { Notification } from "@progress/kendo-react-notification";
import { userService } from "../../../services/user/UserService";

const customInput = (fieldRenderProps: FieldRenderProps) => {
  const { label, type, value, placeholder, onChange } = fieldRenderProps;
  return (
    <FieldWrapper>
      <Label>{label}</Label>
      <Input
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
      />
    </FieldWrapper>
  );
};

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const logIn = () => {
    // navigate("/app");
    navigate("/");
  };

  const handlePasswordReset = async () => {
    try {
      const emailObj = {
        email: email,
      };
      await userService.requestPasswordReset(emailObj);
      alert("Password reset request sent. Check your email.");
      navigate("/");
    } catch (err) {
      alert("Failed to send password reset request");
      console.error(err);
    } finally {
    }
  };

  return (
    <Form
      onSubmit={handlePasswordReset}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "80%" }}>
          <legend className="mb-5">Forgot Your Password?</legend>
          <Field
            name="email"
            label="Email *"
            placeholder="Email"
            component={customInput}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className="k-form-buttons mt-5">
            <Button themeColor={"primary"} style={{ width: "100%" }}>
              Request Password Reset
            </Button>
          </div>

          <div className="resend-code-container mt-2">
            <a
              href="#"
              onClick={logIn}
              className="resend-code-link"
              style={{ textDecoration: "none" }}
            >
              Back To Login
            </a>
          </div>
        </FormElement>
      )}
    ></Form>
  );
};

export default ForgotPassword;
