import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserProvider } from "./context/user/UserProvider";
import RoutesWrapper from "./routes/components/RoutesWrapper";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <RoutesWrapper />
      </UserProvider>
    </div>
  );
}

export default App;
