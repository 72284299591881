import React, { useState } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  Field,
  FormElement,
  FieldRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Label, Error } from "@progress/kendo-react-labels";
import "./TwoFactor.scss";
import { useNavigate } from "react-router-dom";

const codeInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, label, id, valid, ...others } =
    fieldRenderProps;
  return (
    <div className="form-group">
      <Label>{label}</Label>
      <Input id={id} type="text" {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const TwoFactor: React.FC = () => {
  const navigate = useNavigate();
  const [isResending, setIsResending] = useState(false);

  const handleFormSubmit = (dataItem: any) => {
    console.log("Entered Code:", dataItem.code);
  };

  const resendCode = () => {
    setIsResending(true);
    console.log("Resend code triggered");
    setTimeout(() => setIsResending(false), 2000);
  };

  const signIn = () => {
    navigate("/app/dashboard");
  };

  return (
    <div className="background d-flex align-items-center justify-content-center two-factor-container">
      <div className="card two-factor-card">
        <Form
          onSubmit={handleFormSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <legend className="two-factor-title">
                Two-factor Authentication
              </legend>
              <p className="two-factor-description">
                Enter the 6-digit code that was sent to your email
              </p>

              <Field name={"code"} label={"Enter Code"} component={codeInput} />

              <div className="form-group mt-4">
                <Button
                  themeColor={"primary"}
                  style={{ width: "100%" }}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit}
                  onClick={signIn}
                >
                  Verify
                </Button>
              </div>

              <div className="resend-code-container mt-2">
                <a
                  href="#"
                  onClick={resendCode}
                  className="resend-code-link"
                  style={{ textDecoration: "none" }}
                >
                  {isResending ? "Resending..." : "Resend code"}
                </a>
              </div>
            </FormElement>
          )}
        />
      </div>
    </div>
  );
};

export default TwoFactor;
