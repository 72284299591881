import { showAlertGlobal } from '../../context/alert/AlertProvider';

export const responseInterceptorError = (error: any) => {
  const { response } = error;
  const statusCode = response?.status;
  const errorMessage =
    response?.data?.message || error.message || 'An unexpected error occurred';

  switch (statusCode) {
    case 400:
      showAlertGlobal('Bad Request: ' + errorMessage, 'error');
      break;

    case 401:
      showAlertGlobal('Unauthorized: Please log in again.', 'warning');
      break;

    case 403:
      showAlertGlobal(
        'Forbidden: You do not have permission to perform this action.',
        'error'
      );
      break;

    case 404:
      showAlertGlobal(
        'Not Found: The requested resource does not exist.',
        'error'
      );
      break;

    case 500:
      showAlertGlobal(
        'Server Error: Something went wrong on our end.',
        'error'
      );
      break;

    case 503:
      showAlertGlobal('Service Unavailable: Please try again later.', 'error');
      break;

    default:
      showAlertGlobal(errorMessage, 'error');
      break;
  }

  if ([400, 401, 403, 404, 500, 503].includes(statusCode)) {
    return Promise.resolve();
  }

  return Promise.reject(error);
};
