import { useEffect, useState } from "react";
import { PRIVILEGES_MOCKS } from "../../mocks/privileges";
import UserContext from "./UserContext";
import { IUser } from "../../routes";

type TUserProviderProps = {
  children: React.ReactNode | React.ReactFragment | React.ReactPortal;
};

export const UserProvider: React.FC<TUserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<IUser>({
    username: "",
    isAuthenticated: false,
    privileges: PRIVILEGES_MOCKS.read,
  });
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      setUser({
        username: "",
        isAuthenticated: false,
        privileges: PRIVILEGES_MOCKS.read,
      });
    }
    setIsInitialized(true);
  }, []);

  const saveUser = (newUser: IUser) => {
    setUser(newUser);
    localStorage.setItem("user", JSON.stringify(newUser));
  };

  const logout = () => {
    setUser({
      username: "",
      isAuthenticated: false,
      privileges: PRIVILEGES_MOCKS.read,
    });
    localStorage.removeItem("user");
  };

  return (
    <UserContext.Provider value={{ user, saveUser, logout, isInitialized }}>
      {children}
    </UserContext.Provider>
  );
};
