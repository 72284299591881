import React, { useEffect, useState } from "react";
import "./Register.scss";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  Label,
  Error,
  Hint,
  FloatingLabel,
} from "@progress/kendo-react-labels";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
  FieldRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useNavigate, useParams } from "react-router-dom";
import { userService } from "../../../services/user/UserService";

const CustomInput = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    visited,
    label,
    id,
    valid,
    value,
    onChange,
    disabled,
    ...others
  } = fieldRenderProps;

  const handleChange = (e: any) => {
    onChange(e.target.value); // Pass the input's value to the onChange handler
  };

  return (
    <FieldWrapper>
      <Label>{label}</Label>
      <Input
        id={id}
        valid={visited && valid}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        {...others}
      />
    </FieldWrapper>
  );
};

const Register: React.FC = () => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();

  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("password");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [status, setStatus] = useState(true);
  const [phone, setPhone] = useState("");
  const [facility, setFacility] = useState("");
  const [email, setEmail] = useState("");

  const updateUser = async () => {
    const data = {
      username,
      firstName,
      lastName,
      password,
      oldPassword,
      passwordConfirm,
      status,
    };
    console.log(data);
    try {
      await userService.updateUser(userId!, data);
      navigate("/");
    } catch (error) {
      alert("Failed to update user. Please try again.");
    }
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const userDetail = await userService.getUserDetail(userId);
        setPhone(userDetail.phone);
        setFacility(userDetail.facility);
        setEmail(userDetail.email);
        setUsername(userDetail.username);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
      }
    };
    getUser();
  }, [userId]);

  return (
    <Form
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "80%" }}>
          <legend className="mb-5">Accept Invitation</legend>
          <FieldWrapper>
            <Label>Username *</Label>
            <Input
              type="username"
              value={username}
              onChange={(e) => setUsername(e.value)}
              placeholder="Username"
              style={{ width: "100%" }}
              disabled
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>First Name *</Label>
            <Input
              type="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.value)}
              placeholder="First Name"
              style={{ width: "100%" }}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Last Name *</Label>
            <Input
              type="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.value)}
              placeholder="Last Name"
              style={{ width: "100%" }}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Email *</Label>
            <Input
              type="phone"
              value={email}
              onChange={(e) => setEmail(e.value)}
              placeholder="email"
              style={{ width: "100%" }}
              disabled
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Phone *</Label>
            <Input
              type="phone"
              value={phone}
              onChange={(e) => setPhone(e.value)}
              placeholder="Phone"
              style={{ width: "100%" }}
              disabled
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Facility *</Label>
            <Input
              type="text"
              value={facility}
              onChange={(e) => setFacility(e.value)}
              placeholder="Phone"
              style={{ width: "100%" }}
              disabled
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Password *</Label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.value)}
              placeholder="Password"
              style={{ width: "100%" }}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Confirm Password *</Label>
            <Input
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.value)}
              placeholder="Confirm password"
              style={{ width: "100%" }}
            />
          </FieldWrapper>

          <div className="k-form-buttons mt-5">
            <Button
              themeColor={"primary"}
              style={{ width: "100%" }}
              onClick={updateUser}
            >
              Sign Up
            </Button>
          </div>
        </FormElement>
      )}
    ></Form>
  );
};

export default Register;
