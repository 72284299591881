import { useState } from "react";
import AlertComponent, { AlertType } from "../../components/alert/Alert";
import AlertContext from "./AlertContext";

let showAlertOutsideContext: (
  message: string,
  type: AlertType
) => void = () => {};

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<AlertType | null>(null);

  const showAlert = (message: string, type: AlertType) => {
    setAlertMessage(message);
    setAlertType(type);
  };

  const clearAlert = () => {
    setAlertMessage(null);
    setAlertType(null);
  };

  showAlertOutsideContext = showAlert;

  return (
    <AlertContext.Provider value={{ showAlert, clearAlert }}>
      {children}
      {alertMessage && (
        <div
          style={{
            position: "fixed",
            top: "10px",
            right: "10px",
            zIndex: 9999,
          }}
        >
          <AlertComponent
            message={alertMessage}
            type={alertType}
            onClose={clearAlert}
          />
        </div>
      )}
    </AlertContext.Provider>
  );
};

export const showAlertGlobal = (message: string, type: AlertType) => {
  showAlertOutsideContext(message, type);
};
