import React, { useState } from "react";
import "./ResetPassword.scss";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  Label,
  Error,
  Hint,
  FloatingLabel,
} from "@progress/kendo-react-labels";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
  FieldRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useNavigate } from "react-router-dom";
import { Notification } from "@progress/kendo-react-notification";

const customInput = (fieldRenderProps: FieldRenderProps) => {
  const { label, type, value, onChange } = fieldRenderProps;
  return (
    <FieldWrapper>
      <Label>{label}</Label>
      <Input
        placeholder={label}
        type={type}
        value={value}
        onChange={onChange}
      />
    </FieldWrapper>
  );
};

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const signIn = () => {
    navigate("/app");
  };

  const logIn = () => {
    // navigate("/app");
    navigate("/");
  };

  const handlePasswordReset = async () => {
    // try {
    //   await userService.requestPasswordReset(email); // Call the password reset API
    //   alert("Password reset request sent. Check your email.");
    //   navigate("/app");
    // } catch (err) {
    //   // setError("Failed to send password reset request.");
    //   console.error(err);
    // } finally {
    //   // setLoading(false);
    // }
  };

  return (
    <Form
      onSubmit={handlePasswordReset}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "80%" }}>
          <legend className="mb-5">Reset Password</legend>
          <Field
            name="password"
            label="Password"
            component={customInput}
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Field
            name="password"
            label="Confirm Password"
            component={customInput}
            type="text"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />

          <div className="k-form-buttons mt-5">
            <Button
              themeColor={"primary"}
              style={{ width: "100%" }}
              onClick={signIn}
            >
              Reset password
            </Button>
          </div>
        </FormElement>
      )}
    ></Form>
  );
};

export default ResetPassword;
