import { FC } from 'react';
import TwoFactor from '../pages/auth/two-factor/TwoFactor';
import Layout from '../components/layout/Layout';
import Users from '../pages/app/users/Users';
import Reports from '../pages/app/reports/Reports';
import Umm from '../pages/app/umm/Umm';
import Settings from '../pages/app/settings/Settings';
import Page404 from '../pages/error/Page404';
import Dashboard from '../pages/app/dashboard/Dashboard';
import ForgotPassword from '../pages/auth/forgot-password/ForgotPassword';
import Register from '../pages/auth/register/Register';
import ResetPassword from '../pages/auth/reset-password/ResetPassword';
import LoginContainer from '../pages/auth/login/LoginContainer';
import AuthenticationLayout from '../components/authenticationLayout/AuthenticationLayout';

export interface IRoute {
  id: string;
  exact?: boolean;
  path: string;
  component?: FC;
  isProtected?: boolean;
  children?: IRoute[];
  redirect?: string;
}

export const appRoutes: IRoute[] = [
  {
    id: 'auth',
    path: '/auth',
    component: AuthenticationLayout,
    isProtected: false,
    children: [
      {
        id: 'login',
        exact: true,
        path: 'login',
        component: LoginContainer,
        isProtected: false,
      },
      {
        id: 'register',
        path: 'register/:userId',
        component: Register,
        exact: true,
        isProtected: false,
      },
      {
        id: 'twoFactor',
        path: 'two-factor',
        component: TwoFactor,
        exact: true,
        isProtected: false,
      },
      {
        id: 'forgotPassword',
        path: 'forgot-password',
        component: ForgotPassword,
        exact: true,
        isProtected: false,
      },
      {
        id: 'resetPassword',
        path: 'reset-password',
        component: ResetPassword,
        exact: true,
        isProtected: false,
      },
    ],
  },
  {
    id: 'app',
    path: '/app',
    component: Layout,
    isProtected: true,
    children: [
      {
        id: 'dashboard',
        path: 'dashboard',
        component: Dashboard,
        exact: true,
        isProtected: true,
      },
      {
        id: 'users',
        path: 'users',
        component: Users,
        exact: true,
        isProtected: true,
      },
      {
        id: 'reports',
        path: 'reports',
        component: Reports,
        exact: true,
        isProtected: true,
      },
      {
        id: 'umm',
        path: 'umm',
        component: Umm,
        exact: true,
        isProtected: true,
      },
      {
        id: 'settings',
        path: 'settings',
        component: Settings,
        exact: true,
        isProtected: true,
      },
    ],
  },
  {
    id: 'page404',
    path: '*',
    component: Page404,
    isProtected: false,
  },
];
