import React from "react";
import { Features, Permissions } from "../../../routes";
import { PermissionProvider } from "../../../context/permission/PermissionProvider";
import {
  fetchPermissions,
  Restricted,
} from "../../../components/restricted/Restricted";
import { useUser } from "../../../hooks/useUser";

const Dashboard: React.FC = () => {
  const { user } = useUser();

  return (
    <React.Fragment>
      <div className="alert alert-primary m-3" role="alert">
        Dashboard under development.
      </div>
      {/* <PermissionProvider fetchPermission={fetchPermissions(user.privileges)}>
        <Restricted feature={Features.FEATURE_A} permission={Permissions.read}>
          <p>Read permission</p>
        </Restricted>
        <Restricted
          feature={Features.FEATURE_A}
          permission={Permissions.create}
        >
          <p>Create permission</p>
        </Restricted>
      </PermissionProvider> */}
    </React.Fragment>
  );
};

export default Dashboard;
