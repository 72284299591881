import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";
import React from "react";

interface LabelInputProps extends FieldRenderProps {
  type?: string;
  id?: string;
  disabled?: boolean;
}

const LabelInput: React.FC<LabelInputProps> = (fieldRenderProps) => {
  const {
    validationMessage,
    visited,
    label,
    id,
    valid,
    disabled,
    type = "text",
    value,
    onChange,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    visited && validationMessage;

  return (
    <FieldWrapper>
      <Label
        className="k-form-label"
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <div className="k-form-field-wrap">
        <Input
          valid={valid}
          type={type}
          id={id}
          disabled={disabled}
          value={value}
          onChange={onChange}
          {...others}
        />
        {/* {!showValidationMessage && (
            <Hint>Enter your personal email address.</Hint>
          )} */}
        {showValidationMessage && <Error>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};

export default LabelInput;
