import apiClient from '../../api/ApiClient';
import endpoints from '../../api/Endpoints';
import { AuthCredentials, AuthResponse } from './AuthInterface';

// interface AuthResponse {
//   token: string;
//   record: {
//     id: string; // User ID
//     avatar: string; // User avatar URL
//     collectionId: string; // Collection ID
//     collectionName: string; // Collection name
//     created: string; // Creation timestamp
//     email: string; // User email
//     emailVisibility: boolean; // Email visibility flag
//     name: string; // User name
//     updated: string; // Last updated timestamp
//     username: string; // User's username
//     verified: boolean; // Verification status
//   };
// }

export const authService = {
  login: async (credentials: AuthCredentials): Promise<AuthResponse> => {
    try {
      const response = await apiClient.post<AuthResponse>(
        endpoints.auth.login,
        credentials
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        error.response?.data?.message || 'Invalid username or password'
      );
    }
  },
};
