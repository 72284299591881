import { useState } from "react";
import Login from "./Login";
import { LoginFormState } from "./interfaces/LoginInterface";
import { AuthCredentials } from "../../../services/auth/AuthInterface";
import { authService } from "../../../services/auth/AuthService";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { IUser } from "../../../routes";
import { PRIVILEGES_MOCKS } from "../../../mocks/privileges";
import { useAlert } from "../../../context/alert/useAlert";

const LoginContainer: React.FC = () => {
  const navigate = useNavigate();
  const { saveUser } = useUser();
  const { showAlert } = useAlert();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loginFormState, setLoginFormState] = useState<LoginFormState>({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange = (
    fieldName: keyof LoginFormState,
    value: string
  ) => {
    setLoginFormState((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const mapToAuthCredentials = (
    loginFormState: LoginFormState
  ): AuthCredentials => {
    // return {
    //   identity: loginFormState.username,
    //   password: loginFormState.password,
    // };
    return {
      username: loginFormState.username,
      password: loginFormState.password,
      deviceId: "",
    };
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const credentials = mapToAuthCredentials(loginFormState);
      const response = await authService.login(credentials);
      const { token } = response;
      localStorage.setItem("token", token);
      // localStorage.setItem("user", JSON.stringify(record));

      const user: IUser = {
        username: "",
        isAuthenticated: true,
        privileges: PRIVILEGES_MOCKS.create,
      };
      saveUser(user);

      navigate("/auth/two-factor");
    } catch (error: any) {
      showAlert(error.message, "error");
      // setErrorMessage(
      //   error.message || "Invalid username or password. Please try again"
      // );
      console.error("Authentication failed:", error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Login
      loginFormState={loginFormState}
      onInputChange={handleInputChange}
      onSubmit={handleLogin}
      errorMessage={errorMessage}
      loading={loading}
    />
  );
};

export default LoginContainer;
